<template>
    <section class="overview container-fluid p-3">
        <div class="position-sticky title d-flex pb-2 mb-2">
            <div class="d-flex d-xl-none align-items-center">
                <button-toggle-side-bar></button-toggle-side-bar>
            </div>
            <h1 class="display-4 font-weight-bolder col pl-xl-0 mb-0">
                Overview
            </h1>
        </div>
    </section>
</template>

<script>
import ButtonToggleSideBar from "../components/layout/ButtonToggleSideBar";
export default {
    name: "Overview",
    metaInfo () {
        return {
            "title": "Overview",
        };
    },
    components: {
        ButtonToggleSideBar

    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.overview {

}
</style>
